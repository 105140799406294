
import React from 'react';

const formatDate = (sqlDateTime) => {
	const date = new Date(sqlDateTime);
	const year = date.getFullYear().toString().slice(-2);
	const month = ('0' + (date.getMonth() + 1)).slice(-2);
	const day = ('0' + date.getDate()).slice(-2);
	const hours = ('0' + date.getHours()).slice(-2);
	const minutes = ('0' + date.getMinutes()).slice(-2);
	return `${year}/${month}/${day} ${hours}:${minutes}`;
}
function PrintDate({ sqlDateTime }) {
	return <p>{formatDate(sqlDateTime)}</p>;
}

export { PrintDate,formatDate };
