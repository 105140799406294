import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';

export default function Pagination({ count, page_number, rows, setPageNumber, setRows }) {
  const [page, setPage] = React.useState(page_number);
  const [rowsPerPage, setRowsPerPage] = React.useState(rows);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageNumber(newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    setPageNumber(0)
    setRows(parseInt(event.target.value, 10))
  };
  

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      <TablePagination
        component="div"
        count={count}
        page={page}
        onPageChange={handleChangePage}
        labelRowsPerPage="Lignes par page"
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}